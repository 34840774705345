import * as React from 'react';
import { Dialog } from '@bb-ui/react-library/dist/components/Dialog';
import { useAuth0Context } from '@bb-ui/auth/dist/auth0/Auth0Context';
import { DialogTitle } from '@bb-ui/react-library/dist/components/DialogTitle';
import { DialogContent } from '@bb-ui/react-library/dist/components/DialogContent';
import { DialogActions } from '@bb-ui/react-library/dist/components/DialogActions';
import {
    DefaultButton,
    PrimaryButton,
} from '@bb-ui/react-library/dist/components/Button';
import { Trans, useTranslation } from 'react-i18next';
import { Typography } from '@bb-ui/react-library/dist/components/Typography';
import { Checkbox } from '@bb-ui/react-library/dist/components/Checkbox';
import { FormControlLabel } from '@bb-ui/react-library/dist/components/FormControlLabel';
import {
    ITextFieldProps,
    TextField,
} from '@bb-ui/react-library/dist/components/TextField';
import { MenuItem } from '@bb-ui/react-library/dist/components/MenuItem';
import { Table } from '@bb-ui/react-library/dist/components/Table';
import { TableHead } from '@bb-ui/react-library/dist/components/TableHead';
import { TableRow } from '@bb-ui/react-library/dist/components/TableRow';
import { TableCell } from '@bb-ui/react-library/dist/components/TableCell';
import { TableBody } from '@bb-ui/react-library/dist/components/TableBody';
import classnames from 'classnames';
import { Tooltip } from '@bb-ui/react-library/dist/components';
import { Information } from '@bb-ui/icons/dist/small';
import { useHelpLinks } from '../../../../hooks/useHelpLinks';
import { useApi, IApiConfig } from '../../../../hooks/useApi';
import { getTenantId } from '../../../../utilities/utilities';
import {
    UasMigrationDialogProps,
    UasMigrationIDP,
    UasMigrationUser,
    UasMigrationUsersResponse,
} from './UasMigrationDialog.types';
import { useStyles } from './UasMigrationDialog.styles';
import { useAppConfigContext } from '../../../../contexts/AppConfigProvider';
import { SkeletonTable } from '../../../Skeleton/SkeletonTable';
import { Message } from '../../../Message/Message';
import { UasMigrationStatusDialog } from '../UasMigrationStatusDialog';
import { useUasMigrationStatusContext } from '../../../../contexts/UasMigrationContext';

interface UasMigrationUserConfig {
    selected: boolean;
    idpId: string;
    userName?: string;
    uasUser: UasMigrationUser;
}

export const UasMigrationDialog: React.FunctionComponent<
    UasMigrationDialogProps
> = props => {
    const { onClose } = props;

    const { t } = useTranslation();
    const classes = useStyles(props);
    const { api, uas } = useAppConfigContext();
    const { user: auth0ContextUser } = useAuth0Context();
    const helpLink = useHelpLinks()('getReportingHelp');

    const userApiUrl = encodeURI(
        `${api?.authProvHostname}/v1/data/tenants/${getTenantId(
            auth0ContextUser,
            uas,
        )}/migrations/uas/users`,
    );
    const userApiConfig = React.useMemo<IApiConfig>(
        () => ({ url: userApiUrl }),
        [userApiUrl],
    );
    const {
        data: usersResponse,
        loading,
        error,
    } = useApi<UasMigrationUsersResponse>(userApiConfig);
    const { users, availableIdentityProviders: idps } = usersResponse ?? {};

    // Form data storage - `userConfigs` mapped by `email` for convenience
    const [userConfigs, setUserConfigs] =
        React.useState<Record<string, UasMigrationUserConfig>>();
    const userConfigValues = userConfigs && Object.values(userConfigs);

    // config modification convenience methods
    const getIdpById = React.useCallback(
        (idpId?: string) => idps?.find(({ id }) => id === idpId),
        [idps],
    );
    const defaultUsername = React.useCallback(
        (user?: UasMigrationUser, idp?: UasMigrationIDP) =>
            idp?.type === 'LearnConnector' ? user?.suggestedLearnId : '',
        [],
    );
    type ConfigMod = Partial<Omit<UasMigrationUserConfig, 'email'>>;
    const modifyUserConfigs = React.useCallback(
        (mod: ConfigMod, email?: string) => {
            const adjustConfig = (c: UasMigrationUserConfig) => ({
                ...c,
                ...(Object.keys(mod).includes('idpId')
                    ? {
                          userName: defaultUsername(
                              c.uasUser,
                              getIdpById(mod.idpId),
                          ),
                          ...mod,
                      }
                    : mod),
            });
            setUserConfigs(
                configs =>
                    configs &&
                    Object.fromEntries(
                        Object.entries(configs).map(([e, c]) =>
                            !email || email === e
                                ? [e, adjustConfig(c)]
                                : [e, c],
                        ),
                    ),
            );
        },
        [getIdpById, defaultUsername],
    );

    // set up default configs when data has loaded
    const NO_IDP = '';
    React.useEffect(() => {
        if (users && idps && idps[0]?.id) {
            setUserConfigs(
                Object.fromEntries(
                    users?.map(user => [
                        user.email,
                        {
                            selected: true,
                            idpId: NO_IDP,
                            uasUser: user,
                        } as UasMigrationUserConfig,
                    ]),
                ),
            );
        }
    }, [users, idps, defaultUsername]);

    // IDP selection
    const [useSingleIdp, setUseSingleIdp] = React.useState(true);
    const [selectedSingleIdpId, setSelectedSingleIdpId] =
        React.useState<string>(NO_IDP);

    React.useEffect(() => {
        if (useSingleIdp) {
            modifyUserConfigs({ idpId: selectedSingleIdpId });
        }
    }, [selectedSingleIdpId, useSingleIdp, modifyUserConfigs]);
    const idpSelectOptions = () =>
        idps ? (
            idps.map(idp => (
                <MenuItem key={idp.id} value={idp.id} button>
                    <Typography className={classes.idpOption}>
                        {idp.displayName}
                    </Typography>
                </MenuItem>
            ))
        ) : (
            <MenuItem button />
        );

    const allUsersSelected = !!userConfigValues?.every(
        config => config.selected,
    );
    const someUsersSelected = !!userConfigValues?.find(
        config => config.selected,
    );
    const noUsersToMigrate = users && users.length === 0;

    // Form validation
    const [showErrors, setShowErrors] = React.useState(false);
    const isValidConfig = () =>
        userConfigValues
            ?.filter(({ selected }) => selected)
            .every(
                ({ idpId, userName }, index, array) =>
                    idpId &&
                    userName &&
                    // make sure all target login names are distinct
                    array.findIndex(conf2 => userName === conf2.userName) ===
                        index,
            );

    // convenience functions for constructing error props for each text field
    type FieldErrorProps = Partial<
        Pick<ITextFieldProps, 'error' | 'helperText'>
    >;
    const idpErrorProps = (isError: boolean): FieldErrorProps =>
        isError
            ? {
                  error: true,
                  helperText: (
                      <Trans i18nKey="settings.uasMigration.validation.idpMissing">
                          Please select an <span lang="en">IdP</span>.
                      </Trans>
                  ),
              }
            : {};
    const singleIdpErrorProps = (): FieldErrorProps =>
        idpErrorProps(showErrors && useSingleIdp && !selectedSingleIdpId);
    const userIdpErrorProps = (
        config?: UasMigrationUserConfig,
    ): FieldErrorProps =>
        idpErrorProps(
            showErrors && !useSingleIdp && !!config?.selected && !config?.idpId,
        );

    const nameErrorProps = (
        config?: UasMigrationUserConfig,
    ): FieldErrorProps => {
        if (!userConfigs || !showErrors || !config?.selected) {
            return {};
        }
        if (!config?.userName) {
            return {
                error: true,
                helperText: t('settings.uasMigration.validation.nameMissing'),
            };
        }
        if (
            userConfigValues!.filter(
                ({ selected, userName }) =>
                    selected && userName === config.userName,
            ).length > 1
        ) {
            return {
                error: true,
                helperText: t('settings.uasMigration.validation.nameDuplicate'),
            };
        }
        return {};
    };

    // convert form data to request body format
    const getResultData = () =>
        userConfigs && {
            userIds: Object.fromEntries(
                Object.entries(userConfigs).map(
                    ([email, { selected, uasUser, ...config }]) => [
                        email,
                        selected ? { ...config } : { remove: true },
                    ],
                ),
            ),
        };

    const [showConfirmation, setShowConfirmation] = React.useState(false);

    const launchApiUrl = encodeURI(
        `${api?.authProvHostname}/v1/data/tenants/${getTenantId(
            auth0ContextUser,
            uas,
        )}/migrations/uas`,
    );
    const launchApiConfig = React.useMemo<IApiConfig>(
        () => ({ url: launchApiUrl, method: 'POST', executeType: 'manual' }),
        [launchApiUrl],
    );

    const {
        data: launchData,
        loading: launching,
        error: launchError,
        execute: launchMigration,
    } = useApi(launchApiConfig);

    const { restartPolling } = useUasMigrationStatusContext();

    React.useEffect(() => {
        if (launchData) {
            restartPolling();
        }
    }, [launchData, restartPolling]);

    const handleNextClick = () => {
        if (isValidConfig()) {
            setShowConfirmation(true);
        } else {
            setShowErrors(true);
        }
    };

    const handleStartClick = () => {
        launchMigration({ data: getResultData() });
    };

    const handleBackClick = () => {
        if (showConfirmation) {
            setShowConfirmation(false);
        } else {
            // TODO cancel confirmation
            onClose();
        }
    };

    const showLaunchStatus = launching || launchError || launchData;

    const renderSingleIdPSection = () => (
        <div className={classes.singleIdpGroup}>
            <FormControlLabel
                label={
                    <Trans i18nKey="settings.uasMigration.singleIdpCheckboxLabel">
                        Use the same <span lang="en">IdP</span> for all users
                    </Trans>
                }
                htmlFor="uas-migration-single_idp_checkbox"
                control={
                    <Checkbox
                        value="uas-migration-single_idp_checkbox"
                        id="uas-migration-single_idp_checkbox"
                        data-testid="uas-migration-single_idp_checkbox"
                        disabled={!idps}
                        checked={!idps || useSingleIdp}
                        onChange={({ target: { checked } }) =>
                            setUseSingleIdp(checked)
                        }
                    />
                }
            />
            <TextField
                className={classes.singleIdpSelect}
                select
                id="uas-migration-single_idp_select"
                data-testid="uas-migration-single_idp_select"
                label={
                    <Trans i18nKey="settings.uasMigration.singleIdpSelectLabel">
                        Assign <span lang="en">IdP</span>
                    </Trans>
                }
                value={useSingleIdp ? selectedSingleIdpId : NO_IDP}
                onChange={event => setSelectedSingleIdpId(event.target.value)}
                disabled={!idps || !useSingleIdp}
                {...singleIdpErrorProps()}
            >
                {idpSelectOptions()}
            </TextField>
        </div>
    );

    function renderUserIdpCell(
        index: number,
        config?: UasMigrationUserConfig,
        user?: UasMigrationUser,
    ) {
        return (
            <TableCell>
                <TextField
                    select
                    id={`uas-migration-user_${index}_idp_select`}
                    value={config?.idpId ?? ''}
                    className={classes.userIdpSelect}
                    onChange={event =>
                        modifyUserConfigs(
                            {
                                idpId: event.target.value,
                            },
                            user?.email,
                        )
                    }
                    disabled={useSingleIdp}
                    {...userIdpErrorProps(config)}
                >
                    {idpSelectOptions()}
                </TextField>
            </TableCell>
        );
    }

    function renderUserEmailCell(
        index: number,
        config?: UasMigrationUserConfig,
        user?: UasMigrationUser,
    ) {
        return (
            <TableCell>
                <TextField
                    id={`uas-migration-user_${index}-username-field`}
                    value={config?.userName ?? ''}
                    className={classes.usernameField}
                    onChange={event =>
                        modifyUserConfigs(
                            {
                                userName: event.target.value,
                            },
                            user?.email,
                        )
                    }
                    {...nameErrorProps(config)}
                />
            </TableCell>
        );
    }

    const renderUserTable = () => (
        <Table
            id="uas-migration-user_table"
            data-testid="uas-migration-user_table"
        >
            <TableHead>
                <TableRow className={classes.tableHeaderRow}>
                    {!showConfirmation && (
                        <TableCell
                            role="columnheader"
                            className={classnames(
                                classes.tableHeader,
                                classes.checkboxCell,
                            )}
                        >
                            <Checkbox
                                value="select_all_users_checkbox"
                                id="uas-migration-select_all_users_checkbox"
                                checked={allUsersSelected}
                                indeterminate={
                                    someUsersSelected && !allUsersSelected
                                }
                                onChange={({ target: { checked } }) =>
                                    modifyUserConfigs({
                                        selected: checked,
                                    })
                                }
                                inputProps={{
                                    'aria-label':
                                        'settings.uasMigration.selectAllUsersAriaLabel',
                                }}
                            />
                        </TableCell>
                    )}
                    <TableCell
                        role="columnheader"
                        className={classes.tableHeader}
                    >
                        {t('settings.uasMigration.email')}
                    </TableCell>
                    <TableCell
                        role="columnheader"
                        className={classes.tableHeader}
                    >
                        {t('settings.uasMigration.username')}
                        {!showConfirmation && (
                            <Tooltip
                                title={
                                    /* prettier-ignore */
                                    <Trans i18nKey="settings.uasMigration.usernameHint">
                                        <span lang="en">Learn</span> usernames are prefilled
                                        and suggested to match. If you change them, verify
                                        that any edited or new username is valid for the selected
                                        <span lang="en">IdP</span>.
                                    </Trans>
                                }
                                placement="right"
                            >
                                <Information
                                    className={classes.usernameInfoIcon}
                                />
                            </Tooltip>
                        )}
                    </TableCell>
                    {(!useSingleIdp || showConfirmation) && (
                        <TableCell
                            role="columnheader"
                            className={classes.tableHeader}
                        >
                            {t('settings.uasMigration.idp', { lng: 'en' })}
                        </TableCell>
                    )}
                </TableRow>
            </TableHead>
            <TableBody data-testid="service-account-list">
                {users
                    ?.map(user => ({
                        user,
                        config: userConfigs && userConfigs[user.email],
                    }))
                    .map(({ user, config }, index) => (
                        <TableRow key={`uas-migration-user_${index}-row`}>
                            {!showConfirmation && (
                                <TableCell className={classes.checkboxCell}>
                                    <Checkbox
                                        value={`uas-migration-user_${index}-select-checkbox`}
                                        id={`uas-migration-user_${index}-select-checkbox`}
                                        checked={!!config?.selected}
                                        onChange={event =>
                                            modifyUserConfigs(
                                                {
                                                    selected:
                                                        event.target.checked,
                                                },
                                                user.email,
                                            )
                                        }
                                        aria-label={t(
                                            'settings.uasMigration.selectUserAriaLabel',
                                            { userName: user.name },
                                        )}
                                    />
                                </TableCell>
                            )}
                            <TableCell>{user.email}</TableCell>
                            {showConfirmation &&
                                (config?.selected ? (
                                    <>
                                        <TableCell>
                                            {config?.userName}
                                        </TableCell>
                                        <TableCell>
                                            {
                                                getIdpById(config?.idpId)
                                                    ?.displayName
                                            }
                                        </TableCell>
                                    </>
                                ) : (
                                    <TableCell colSpan={2}>
                                        <Typography
                                            role="alert"
                                            className={classes.userAlert}
                                        >
                                            {t(
                                                'settings.uasMigration.toBeRemoved',
                                            )}
                                        </Typography>
                                    </TableCell>
                                ))}
                            {!showConfirmation && (
                                <>
                                    {renderUserEmailCell(index, config, user)}
                                    {!useSingleIdp &&
                                        renderUserIdpCell(index, config, user)}
                                </>
                            )}
                        </TableRow>
                    ))}
            </TableBody>
        </Table>
    );

    const content = error ? (
        <Message
            variant="error"
            message={t('settings.uasMigration.loadError')}
        />
    ) : (
        <form className={classes.content}>
            {showConfirmation ? (
                <>
                    <Typography component="p">
                        {t('settings.uasMigration.confirmation')}
                    </Typography>
                    <Typography component="p">
                        {/* prettier-ignore */}
                        <Trans i18nKey="settings.uasMigration.assignGroups">
                            Once the migration is completed, please make sure the target users
                            are assigned to existing roles (groups) with
                            <span lang="en">Snowflake</span> access privilege.
                            You can also create new roles (groups)
                            in the target <span lang="en">IdP</span> with one of the following IDs
                            and assign them to the target users:
                            <span lang="en" className={classes.monospace}>ILLUMINATE_D</span> (developer),
                            <span lang="en" className={classes.monospace}>ILLUMINATE_R</span> (reporting),
                            <span lang="en" className={classes.monospace}>ILLUMINATE_RV</span> (restricted viewer),
                            <span lang="en" className={classes.monospace}>ILLUMINATE_A</span> (author).
                        </Trans>
                    </Typography>
                </>
            ) : (
                <>
                    <Typography component="p">
                        {/* prettier-ignore */}
                        <Trans i18nKey="settings.uasMigration.description">
                            This procedure migrates your institution to the <span lang="en">Universal Authentication System</span>
                            where users sign in with their institutional accounts only. Previous <span lang="en">Illuminate</span>
                            accounts and their access will be removed.
                        </Trans>
                    </Typography>
                    {users &&
                        (noUsersToMigrate ? (
                            <Typography
                                component="p"
                                data-testid="uas-migration-no-users"
                            >
                                {/* prettier-ignore */}
                                <Trans i18nKey="settings.uasMigration.noSnowflakeDataToMigrate">
                                    Your institution has no <span lang="en">Snowflake</span> data to
                                    migrate. You can start the migration to <span lang="en">UAS</span> now.
                                </Trans>
                            </Typography>
                        ) : (
                            <Typography component="p">
                                {/* prettier-ignore */}
                                <Trans i18nKey="settings.uasMigration.snowflakeMigration">
                                    Your institution has users with open <span lang="en">Snowflake</span> worksheets.
                                    Select the users whose worksheets you want to keep.
                                    To link their owners to corresponding users in <span lang="en">UAS</span>,
                                    write a username and select an <span lang="en">IdP</span> for each.
                                    <strong>
                                        Worksheets of users not selected for the migration will be lost.
                                    </strong><br/>
                                    If you need to migrate accounts to a new <span lang="en">IdP</span>, please
                                    <a href={helpLink} target="_blank" rel="noreferrer">
                                        contact Support
                                    </a>.
                                </Trans>
                            </Typography>
                        ))}
                </>
            )}
            {!showConfirmation && !noUsersToMigrate && renderSingleIdPSection()}
            {users && !noUsersToMigrate && (
                <div className={classes.tableContainer}>
                    {!showConfirmation && (
                        <Typography
                            variant="h2"
                            className={classes.userSectionTitle}
                            data-testid="uas-migration-users-section-title"
                        >
                            {/* prettier-ignore */}
                            <Trans
                                i18nKey="settings.uasMigration.usersSectionTitle"
                                values={{userCount: users.length}}
                            >
                                Users with <span lang="en">Snowflake</span> data
                                ({{ userCount: users.length }})
                            </Trans>
                        </Typography>
                    )}
                    {renderUserTable()}
                </div>
            )}
            {loading && <SkeletonTable columnNum={2} rowNum={4} />}
        </form>
    );

    return (
        <>
            <Dialog maxWidth="lg" open={!showLaunchStatus}>
                <DialogTitle id="uas-migration-dialog-title" onClose={onClose}>
                    { /* prettier-ignore */}
                    <Trans i18nKey="settings.uasMigration.title">
                        Migration to <span lang="en">Universal Authentication</span>
                        (<span lang="en">UAS</span>)
                    </Trans>
                </DialogTitle>
                <DialogContent>{content}</DialogContent>
                <DialogActions>
                    <DefaultButton onClick={handleBackClick}>
                        {t('general.back')}
                    </DefaultButton>
                    {showConfirmation || noUsersToMigrate ? (
                        <PrimaryButton onClick={handleStartClick}>
                            {t('settings.uasMigration.startMigration')}
                        </PrimaryButton>
                    ) : (
                        <PrimaryButton
                            onClick={handleNextClick}
                            disabled={!users}
                        >
                            {t('general.next')}
                        </PrimaryButton>
                    )}
                </DialogActions>
            </Dialog>
            <UasMigrationStatusDialog
                open={!!showLaunchStatus}
                loading={launching}
                error={launchError}
                onClose={onClose}
            />
        </>
    );
};
